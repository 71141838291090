import React from 'react'
import styled from 'styled-components'

const Input = styled.input`
    width: 100%;
    padding: 16px 16px 16px 48px;
    max-height: 64px;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    font-size: 1.25rem;
    line-height: 1.3;
    color: #2B2B2B;
    transition: all 0.05s linear;
    position: relative;

    &::placeholder {
        font-style: italic;
        font-weight: 400;
        font-size: 1.125rem;
        line-height: 1.3;
        color: #BFBFBF;
    }

    &:focus {
        outline: 5px solid rgba(245, 102, 0, 0.1);
        border-color: rgba(245, 102, 0, 0.5);
        color: #F56600;
    }
`


const Label = styled.label`
    display: flex;
    flex-direction: column;
    gap: 12px;
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 1.3;
    color: #000000;

  @media (max-width: 767.98px) {
    font-size: 1rem;
  }
`

const Container = styled.div`
    position: relative;

    &::after {
        position: absolute;
        content: "$";
        color: #777777;
        top: 50%;
        transform: translateY(-50%);
        left: 20px;
    }
`

export class Textfield extends React.Component {
    render() {
        const placeholder = this.props.placeholder;
        const type = this.props.type;
        const label = this.props.label;
        return (
            <Label>
                {label}
                <Container>
                    <Input placeholder={placeholder} type={type} {...this.props} />
                </Container>
            </Label>
        );
    }
}
