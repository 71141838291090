import React from 'react'
import styled from 'styled-components'

const CustomLabel = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;

    p {
        font-weight: 400;
        font-size: 1.25rem;
        line-height: 1.3;
        color: #000000;
        margin: 0;    

        @media (max-width: 767.98px) {
            font-size: 1rem;
        }
    }
`

export class Label extends React.Component {
    render() {
        return (
            <div>
                <CustomLabel>
                    <p>{this.props.labelValue}</p>
                    {this.props.children}
                </CustomLabel>
            </div>
        );
    }
}
