import React from 'react';
import { Label } from './components/input/label'
import { CtaButtonLink, ButtonContainer, Button } from './components/input/button'
import { Textfield } from './components/input/textfield'
import styled from 'styled-components'
import _ from 'lodash'
import ReactCanvasConfetti from 'react-canvas-confetti'
const MAX_REBATE_FACTOR = 0.5 // 50%
const MAX_LMI_REBATE_FACTOR = 0.8 // 50%
export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      zipCode: null,
      energySavings: null,
      projectCost: null,
      isLMI: null,
      rebateAmount: 0,
    }
    this.handleChange = this.handleChange.bind(this);
  }

  getInstance = (instance) => {
    // saving the instance to an internal property
    this.confetti = instance;
  }


  handleChange(e) {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
      rebateAmount: this.calculateRebate(),
    })

  }



  componentDidUpdate() {
    const rebateAmount = this.calculateRebate()
    if (rebateAmount > this.state.rebateAmount) {
      this.confetti();
    }
  }

  calculateRebate = () => {
    if (this.state.energySavings === "Less than 20%") {
      return 0
    }
    // LMI
    if (this.state.isLMI === "true") {
      if (this.state.energySavings === "20% to 35%") {
        if (this.state.projectCost > 5000) {
          return 4000
        }
        return this.state.projectCost * MAX_LMI_REBATE_FACTOR
      }
      if (this.state.energySavings === "More than 35%") {
        if (this.state.projectCost > 10000) {
          return 8000
        }
        return this.state.projectCost * MAX_LMI_REBATE_FACTOR

      }
    }
    // MARKET RATE
    if (this.state.isLMI === "false") {
      if (this.state.energySavings === "20% to 35%") {
        if (this.state.projectCost > 4000) {
          return 2000
        }
        return this.state.projectCost * MAX_REBATE_FACTOR
      }
      if (this.state.energySavings === "More than 35%") {
        if (this.state.projectCost > 8000) {
          return 4000
        }
        return this.state.projectCost * MAX_REBATE_FACTOR
      }
    }
    return 0
  }

  numberWithCommas(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  render() {
    const calculateRebate = this.numberWithCommas(_.round(this.calculateRebate(), 0)) || 0
    const lMIOptions = [["true", "Yes"], ["false", "No"]]
    const energySavingsOptions = [
      "Less than 20%",
      "20% to 35%",
      "More than 35%",]
    return (
      <div className="App">
        <CenterPage>
          <MainContainer>
            <BackgroundContainer>
              <HeaderSection>
                <MainTitle>IRA HOMES Rebate Program Calculator</MainTitle>
                <H2>Modeled Performance Rebates Calculator (US)</H2>
              </HeaderSection>
              <TwoColumns>
                <LeftColumns>
                  {/*
                    <Textfield
                      label="1. Enter your zip code:"
                      value={this.state.zipCode}
                      onChange={this.handleChange}
                      placeholder="Eg. 80305"
                      type="number" />

                  */}
                  <Label labelValue="1. Is your household low or moderate income?**">
                    <ButtonContainer>
                      {lMIOptions.map(option => (
                        <Button key={option[0]} name="isLMI" value={option[0]} isActive={this.state.isLMI === option[0]} onClick={this.handleChange}>{option[1]}</Button>
                      ))}
                    </ButtonContainer>
                  </Label>
                  <Label labelValue="2. How much energy will you save?">
                    <ButtonContainer>
                      {energySavingsOptions.map(option => (
                        <Button name="energySavings" key={option} value={option} isActive={this.state.energySavings === option} onClick={this.handleChange}>{option}</Button>
                      ))}
                    </ButtonContainer>
                  </Label>
                  <Textfield
                    label="3. What is your project cost?"
                    placeholder="Eg. 5,000"
                    name="projectCost"
                    value={this.state.projectCost}
                    onChange={this.handleChange}
                    type="number" />
                </LeftColumns>
                <RightColumns>
                  <ReactCanvasConfetti
                    angle={12}
                    className="canvas"
                    colors={[
                      '#26ccff',
                      '#a25afd',
                      '#ff5e7e',
                      '#88ff5a',
                      '#fcff42',
                      '#ffa62d',
                      '#ff36ff'
                    ]}
                    decay={0.8}
                    disableForReducedMotion
                    drift={1.4}
                    gravity={1.3}
                    origin={{
                      x: 0.5,
                      y: 0.5
                    }}
                    particleCount={52}
                    resize
                    scalar={1.2}
                    shapes={[
                      'circle',
                      'square'
                    ]}
                    spread={360}
                    startVelocity={45}
                    ticks={1000}
                    useWorker
                    zIndex={-1}
                    style={{ position: 'absolute', width: '100%', zIndex: -1 }}
                    refConfetti={this.getInstance}
                  />


                  <RebateAmountSection>
                    <RebateAmountSubtitle>Your estimated rebate amount is</RebateAmountSubtitle>
                    <RebateAmount>$ {calculateRebate}<Asterisk>*</Asterisk></RebateAmount>
                  </RebateAmountSection>
                  <CTASection>
                    <CTATitle>Want a customized decarbonization roadmap for your home?</CTATitle>
                    <CtaButtonLink href="https://radiant.formstack.com/forms/decarbonization_roadmap_for_your_home"> Sign up here</CtaButtonLink>
                  </CTASection>
                </RightColumns>
              </TwoColumns>

              <ProgramCTA>Learn more about the HOMES Rebate program: <a href="https://snuggpro.com/blog/item/what-the-HOMES-rebate-program-means-for-states-home-performance-industry">Read our blog post. →</a></ProgramCTA>


              <FinePrint>
                <p>** The term "low or moderate income household'' means an individual or family the total annual income of which is less than 80 percent of the median income of the area in which the individual or family resides, as reported by the Department of Housing and Urban Development, including an individual or family that has demonstrated eligibility for another Federal program with income restrictions equal to or below 80 percent of area median income. In multi-family buildings, at least 50% of the dwellings must qualify as LMI households. </p>
                <p>
                  * This is an estimate, rounded to the nearest whole dollar. Actual rebate amount may vary.
                </p>
              </FinePrint>
            </BackgroundContainer>
          </MainContainer>
          <FinePrint>
            <p>
              This calculator is brought to you by <a href="https://snuggpro.com">Snugg Pro</a> and <a href="https://radiantlabs.co">Radiant Labs</a>
            </p>
          </FinePrint>
        </CenterPage>
      </div>
    )
  }
}






const CenterPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  padding: 40px 0 24px;

  @media (max-width: 767.98px) {
    padding: 16px 0 24px;
  }
`

const MainContainer = styled.div`
  box-shadow: 0px 4px 48px rgba(0, 0, 0, 0.08);
  border: 1px solid #FFD9BD;
  border-radius: 16px;
  max-width: 1024px;
  width: 96%;
  overflow: hidden;
  position: relative;
  z-index: 999;

  &::before, &::after {
    content: "";
    z-index: -999;
    border-radius: 100%;
    position: absolute;
    width: 643px;
    height: 643px;
  }

  &::before {
    background: #FBCFA5;
    left: -75px;
    top: -419px;
  }

  &::after {
    background: #F56600;
    opacity: 0.7;
    left: 557px;
    top: -419px;
  }

  @media (max-width: 991.98px) {
    margin: 16px 0;
  }

  @media (max-width: 767.98px) {
    margin: 8px 0 8px;
  }


`
const Asterisk = styled.div`
  font-size: 0.5em;
  display: block;
  margin-top: -4px;
  height: 50px;
  float: right;
`

const FinePrint = styled.div`
  margin-top: 16px;
  font-size: 0.8em;
  color: #555;

  a {
    color: #F56600;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 767.98px) {
    padding: 0 24px;
    margin-top: 8px;
  }
`

const BackgroundContainer = styled.div`
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(188px);
  padding: 40px;
  border-radius: 16px;

  @media (max-width: 991.98px) {
    padding: 40px 0;
  }
`

const HeaderSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
`

const MainTitle = styled.h1`
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 1.18;
  text-align: center;
  background: linear-gradient(90deg, #F56600 0%, #F66115 14.29%, #F75D22 28.57%,
    #F8582C 42.86%, #F85535 57.14%, #F7513D 71.43%, #F64E44 85.71%, #F54B4B 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin: 0;
  padding: 0;

  @media (max-width: 991.98px) {
    padding: 0 24px;
  }

  @media (max-width: 767.98px) {
    font-size: 1.75rem;
  }

`

const H2 = styled.h2`
  text-align: center;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 10px;

  @media (max-width: 767.98px) {
    font-size: 1.25rem;
    padding: 0 16px;
    margin-top: 24px;
  }
`

const TwoColumns = styled.div`
  display: flex;
  width: 100%;
  gap: 24px;
  padding: 32px 0 20px;

  @media (max-width: 991.98px) {
    flex-direction: column;
  }

`

const LeftColumns = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media (max-width: 991.98px) {
    width: 100%;
    padding-left: 32px;
    padding-right: 32px;
  }
`

const RightColumns = styled(LeftColumns)`
  justify-content: space-between;
  padding-left: 30px;
  position: relative;
  gap: 24px;

  @media (max-width: 991.98px) {
    margin-top: 32px;
  }
`

const RebateAmountSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  @media (max-width: 991.98px) {
    margin-bottom: 32px;
  }
`

const RebateAmountSubtitle = styled.h3`
  font-weight: 500;
  font-size: 1.375rem;
  line-height: 1.3;
  text-align: center;
  color: #2B2B2B;
  margin: 0;
`

const RebateAmount = styled.h2`
  font-weight: 700;
  font-size: 5.0rem;
  line-height: 1;
  text-align: center;
  background: linear-gradient(90deg, #F56600 0%, #F66115 14.29%,
              #F75D22 28.57%, #F8582C 42.86%, #F85535 57.14%, #F7513D 71.43%, #F64E44 85.71%, #F54B4B 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin: 0;
`

const CTASection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    background-color: #fef0e6;
    border-radius: 8px;
    padding: 24px 32px;
`


const CTATitle = styled.h3`
  font-weight: 600;
  font-size: 1.375rem;
  line-height: 1.3;
  text-align: center;
  color: #2B2B2B;
  margin: 0;
`

const ProgramCTA = styled.p`
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5;
  text-align: center;
  color: #2B2B2B;
  margin:0;
  margin-top: 8px;

  a {
    text-decoration: underline;
    color: #F56600;
    display: inline-block;

    &:hover {
      text-decoration: none;
    }
  }

  @media (max-width: 767.98px) {
    line-height: 2;
    margin-bottom: 24px;
  }
`
