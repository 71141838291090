import React from 'react';
import styled from 'styled-components'

const CustomButton = styled.button`
    padding: 16px 10px;
    gap: 8px;
    width: 100%;
    height: 60px;
    background: #FFFFFF;
    border: 1px solid #E6E6E6;
    border-radius: 8px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.03);
    cursor: pointer;
    transition: all 0.05s linear;
    
    font-weight: 500;
    font-size: 0.9375rem;
    line-height: 1.27;
    color: #777777;

    outline-color: #F56600;

    &:hover {
      background: #fffcfa;
      color: #F56600;
      border-color: #F56600;
    }
    
    ${props => props.active && `
        background: #F56600;
        border: 1px solid #F56600;
        color: #FFFFFF;
        
         &:hover {
          background: #F56600;
          border: 1px solid #F56600;
          color: #FFFFFF;
        }
      `
  }

  @media (max-width: 767.98px) {
    height: 48px;
  }
`

const FlexButtonContainer = styled.div`
    display: flex;
    gap: 8px;
    width: 100%;

    @media (max-width: 575.98px) {
        flex-direction: column;
     }
`

const CtaCustomButtonLink = styled.a`
    padding: 16px 10px;
    gap: 8px;
    width: 100%;
    height: 56px;
    border-radius: 8px;
    cursor: pointer;
    text-decoration: none;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04);
    background: linear-gradient(90deg, #6930C3 0%, #AE30C3 100%);
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.3;
    color: #FFFFFF;
    border: none;
    text-align: center;

    &:hover {
     background: linear-gradient(180deg, #6930C3 0%, #AE30C3 100%);
     color: #FFFFFF;
   }

   &:active, &:focus, &.active {
    background: linear-gradient(180deg, #4f15aa 0%, #441096 100%);
    color: #FFFFFF;
  }
`

export class Button extends React.Component {
  render() {
    return (
      <CustomButton active={this.props.isActive} {...this.props}>{this.props.children}</CustomButton>
    );
  }
}

export class ButtonContainer extends React.Component {
  render() {
    return (
      <FlexButtonContainer>
        {this.props.children}
      </FlexButtonContainer>
    );
  }
}

export class CtaButtonLink extends React.Component {
  render() {
    return (
      <CtaCustomButtonLink {...this.props}>{this.props.children}</CtaCustomButtonLink>
    );
  }
}
